import React from "react";
import "./styles.js";

function index() {
  return (
    <div>
      <p>This is a test text</p>
    </div>
  );
}

export default index;
