export const EVENTS_DB = [
  {
    id: 1,
    day: "Monday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: null },
      { time: "12:00", event: null },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: "G1 Kids 4-6" },
      { time: "17:00", event: "G1 Kids 7-10" },
      { time: "18:00", event: "G1 Kids 11-14" },
      { time: "19:00", event: "G1 Fundamentals Jiu Jitsu" },
      { time: "20:00", event: "G1 Advanced Jiu Jitsu" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors: {
      "16:00": " #ff8e0f ",
      "17:00": "#ffe28f",
      "18:00": "#c9dcdf",
      "19:00": "#89bf71",
      "20:00": "#fbc492",
    },
  },
  {
    id: 2,
    day: "Tuesday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: null },
      { time: "12:00", event: null },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: "G2 Kids 4-6  " },
      { time: "17:00", event: "G2 Kids 7-10  " },
      { time: "18:00", event: "G2 Kids 11-14  " },
      { time: "19:00", event: "G1 Fundamentals Jiu Jitsu" },
      { time: "20:00", event: "G1 Female Jiu Jitsu" },
      { time: "21:00", event: "Low Intensity Jiu Jitsu" },
      { time: "22:00", event: null },
    ],
    colors: {
      "16:00": " #ff8e0f ",
      "17:00": "#ffe28f",
      "18:00": "#c9dcdf",
      "19:00": "#89bf71",
      "20:00": "#bb6d96",
      "21:00": "#ffffc2 ",
    },
  },
  {
    id: 3,
    day: "Wednesday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: null },
      { time: "12:00", event: null },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: "G3 Kids 4-6  " },
      { time: "17:00", event: " G3 Kids 7-10   " },
      { time: "18:00", event: "G3 Kids 11-14  " },
      { time: "19:00", event: "G1 Fundamentals Jiu Jitsu" },
      { time: "20:00", event: "G1 Advanced Jiu Jitsu" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors: {
      "16:00": " #ff8e0f ",
      "17:00": "#ffe28f",
      "18:00": "#c9dcdf",
      "19:00": "#89bf71",
      "20:00": "#fbc492",
    },
  },
  {
    id: 4,
    day: "Thursday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: null },
      { time: "12:00", event: null },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: "G1 Kids 4-6  " },
      { time: "17:00", event: "G1 Kids 7-10  " },
      { time: "18:00", event: "G1 Kids 11-14  " },
      { time: "19:00", event: "G1 Fundamentals Jiu Jitsu" },
      { time: "20:00", event: "G1 Female Jiu Jitsu" },
      { time: "21:00", event: "Low Intensity Jiu Jitsu" },
      { time: "22:00", event: null },
    ],
    colors: {
      "16:00": " #ff8e0f ",
      "17:00": "#ffe28f",
      "18:00": "#c9dcdf",
      "19:00": "#89bf71",
      "20:00": "#bb6d96",
      "21:00": "#ffffc2 ",
    },
  },
  {
    id: 5,
    day: "Friday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: null },
      { time: "12:00", event: null },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: "G2 Kids 4-6  " },
      { time: "17:00", event: " G2 Kids 7-10   " },
      { time: "18:00", event: "G2 Kids 11-14  " },
      { time: "19:00", event: "G1 Fundamentals Jiu Jitsu" },
      { time: "20:00", event: "G1 Advanced Jiu Jitsu" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors: {
      "16:00": " #ff8e0f ",
      "17:00": "#ffe28f",
      "18:00": "#c9dcdf",
      "19:00": "#89bf71",
      "20:00": "#fbc492",
    },
  },
  {
    id: 6,
    day: "Saturday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: null },
      { time: "12:00", event: null },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: "G3 Kids 4-6  " },
      { time: "17:00", event: " G3 Kids 7-10   " },
      { time: "18:00", event: "G3 Kids 11-14  " },
      { time: "19:00", event: "G1 Fundamentals Jiu Jitsu" },
      { time: "20:00", event: "G1 Advanced Jiu Jitsu" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors: {
      "16:00": " #ff8e0f ",
      "17:00": "#ffe28f",
      "18:00": "#c9dcdf",
      "19:00": "#89bf71",
      "20:00": "#fbc492",
    },
  },
  // {
  //   id: 7,
  //   day: "Sunday",
  //   events: [
  //     { time: "08:00", event: null },
  //     { time: "09:00", event: null },
  //     { time: "10:00", event: null },
  //     { time: "11:00", event: null },
  //     { time: "12:00", event: null },
  //     { time: "13:00", event: null },
  //     { time: "14:00", event: null },
  //     { time: "15:00", event: null },
  //     { time: "16:00", event: null },
  //     { time: "17:00", event: null },
  //     { time: "18:00", event: null },
  //     { time: "19:00", event: null },
  //     { time: "20:00", event: null },
  //     { time: "21:00", event: null },
  //     { time: "22:00", event: null },
  //   ],
  //   colors: {
  //     "8:00": " #F4F0F0",
  //     "9:00": " #F4F0F0",
  //     "10:00": " #F4F0F0",
  //     "11:00": " #F4F0F0",
  //     "12:00": " #F4F0F0",
  //     "13:00": " #F4F0F0",
  //     "14:00": " #F4F0F0",
  //     "15:00": " #F4F0F0",
  //     "16:00": " #F4F0F0",
  //     "17:00": " #F4F0F0",
  //     "18:00": " #F4F0F0",
  //     "19:00": " #F4F0F0",
  //     "20:00": " #F4F0F0",
  //     "21:00": " #F4F0F0",
  //     "22:00": " #F4F0F0",
  //   },
  // },
];
